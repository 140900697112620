import React from "react"
import styled, { css } from "styled-components"
import BaseImg from "../baseImg"
import { Container } from "../styledComponents"
import SliderControls from "./sliderControls"
import { useSwiper } from "../hooks/useSwiper"
import { useAnimatedBorder } from "../../helpers"

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @media screen and (max-width: 1195px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    margin-top: 70px;
  }
`

const Slider = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1195px) {
    margin-bottom: 20px;
  }
`

const SlideContent = styled.div`
  padding: 3.5rem 2rem;
  position: relative;
  width: 50%;
  @media screen and (max-width: 1195px) {
    max-width: 600px;
    text-align: center;
    width: 100%;
  }
`

const Border = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  height: 100%;
  left: 20%;
  width: 60%;
  height: 40px;
  border-top: 1px solid ${(props) => props.theme.secondary};
  ${(props) =>
    props.side === "bottom" &&
    css`
      top: auto;
      bottom: 0;
      border-top: none;
      border-bottom: 1px solid ${(props) => props.theme.secondary};
    `}
  ::before,
  ::after {
    content: "";
    top: 0;
    position: absolute;
    ${(props) =>
      props.side === "bottom" &&
      css`
        top: auto;
        bottom: 0;
        border-left: none;
      `}
    height: 100%;
    transition: transform 1s ease-out;
  }
  ::before {
    left: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
    ${(props) =>
      props.side === "bottom" &&
      css`
        transform: scaleY(${(props) => 1 - props.scale});
        transform-origin: bottom;
      `}
  }
  ::after {
    right: 0;
    border-right: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: top;
    ${(props) =>
      props.side === "bottom" &&
      css`
        transform: scaleY(${(props) => 1 + props.scale});
        transform-origin: bottom;
      `}
  }
`

const SlideImg = styled(BaseImg)`
  width: 40%;
  margin: 0 40px 0 40px;
  @media screen and (max-width: 1195px) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const SliderControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;

  @media screen and (min-width: 1196px) {
    position: absolute;
    top: 63%;
    left: 25%;
    transform: translateX(-50%);
    justify-content: flex-end;
  }
`

const Referrer = styled.p`
  font: 700 1rem/1.1875 "Montserrat";
`

const ReviewsSlider = ({ reviews }) => {
  const [scale, boxRef] = useAnimatedBorder()
  const { swiperElement, swiperInstance, activeSlideIndex } = useSwiper(reviews)

  return (
    <div style={{ margin: "70px 0 130px 0" }}>
      <Container>
        <Slider>
          <div className="swiper-container" ref={swiperElement}>
            <ul style={{ margin: "0" }} className="swiper-wrapper">
              {reviews.map(
                ({
                  content,
                  referrerName,
                  referrerPosition,
                  referrerImage,
                }) => {
                  return (
                    <Slide className="swiper-slide" key={referrerName}>
                      <SlideImg localFile={referrerImage.localFile} />
                      <SlideContent ref={boxRef}>
                        <Border side="top" scale={scale} />
                        <p
                          dangerouslySetInnerHTML={{ __html: content }}
                          style={{ marginBottom: "1rem" }}
                        ></p>
                        <Referrer>
                          {referrerName}
                          <br />
                          <span style={{ fontWeight: "normal" }}>
                            {referrerPosition}
                          </span>
                        </Referrer>
                        <Border side="bottom" scale={scale} />
                      </SlideContent>
                    </Slide>
                  )
                }
              )}
            </ul>
          </div>
        </Slider>
        <SliderControlsWrapper>
          <SliderControls
            swiperInstance={swiperInstance}
            activeSlideIndex={activeSlideIndex}
            slides={reviews}
          />
        </SliderControlsWrapper>
      </Container>
    </div>
  )
}

export default ReviewsSlider
