import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { BigMobileFont } from "../components/typography"
import { Container, BorderedHeader } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import BorderedHeaderWrapper from "../components/borderedHeaderWrapper"
import ContentImageColumns from "../components/contentImageColumns"
import OverlapSlider from "../components/sliders/overlapSlider"
import TeamSlider from "../components/sliders/teamSlider"
import SideTitleBox from "../components/sideTitleBox"
import ListSection from "../components/listSection"
import ReviewsSlider from "../components/sliders/reviewsSlider"
import SupportSlider from "../components/sliders/supportSlider"
import TechnologiesSection from "../components/technologiesSection"
import LastCTASection from "../components/lastCTASection"

const AboutPage = ({ data }) => {
  const {
    getToKnowTitle,
    getToKnowContentTitle,
    getToKnowContentText,
    getToKnowImage,
    cultureSectionTitle,
    culture,
    peopleSectionTitle,
    peopleSectionContentTitle,
    peopleSectionContentText,
    peopleSectionImage1,
    peopleSectionImage2,
    peopleSectionImage3,
    team,
    listSectionTitle,
    list,
    reviewsSectionTitle,
    reviews,
    supportSectionTitle,
    support,
    technologySectionTitle,
    lastCTASectionTitle,
    lastCTASectionText,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    supportSliderTitle,
    supportSliderText,
  } = data.wordpressPage.cmb2.metabox_about
  const technologies = data.allWordpressWpTechnology.nodes
  const technologyTaxonomy = data.allWordpressWpTechnologyTaxonomy.nodes
  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/about"
      />
      <Container size="medium">
        <h1>
          <BorderedHeaderWrapper size="small">
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {getToKnowTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
        </h1>
        <ContentImageColumns
          title={getToKnowContentTitle}
          content={getToKnowContentText}
          image={getToKnowImage.localFile.childImageSharp.fluid}
        />
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {cultureSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <OverlapSlider slides={culture} />
        <h2>
          <BorderedHeaderWrapper size="medium" side="right">
            <BorderedHeader side="right" whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {peopleSectionTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
        </h2>
        <SideTitleBox
          images={[
            peopleSectionImage1.localFile,
            peopleSectionImage2.localFile,
            peopleSectionImage3.localFile,
          ]}
          subtitle={peopleSectionContentTitle}
          text={peopleSectionContentText}
        />
      </Container>
      <Container>
        <TeamSlider team={team} />
      </Container>
      <ListSection title={listSectionTitle} items={list} position="center" />
      <Container size="medium">
        <BorderedHeaderWrapper size="small">
          <BorderedHeader size="big">
            <BigMobileFont desktopFontSize="2.5rem">
              {reviewsSectionTitle}
            </BigMobileFont>
          </BorderedHeader>
        </BorderedHeaderWrapper>
        <ReviewsSlider reviews={reviews} />
        <BorderedHeaderWrapper size="medium" side="right">
          <BorderedHeader whiteSpace="nowrap" side="right">
            <BigMobileFont desktopFontSize="2.5rem">
              {supportSectionTitle}
            </BigMobileFont>
          </BorderedHeader>
        </BorderedHeaderWrapper>
        <SupportSlider
          support={support}
          title={supportSliderTitle}
          text={supportSliderText}
        />
      </Container>
      <TechnologiesSection
        title={technologySectionTitle}
        technologies={technologies}
        technologyTaxonomy={technologyTaxonomy}
      />
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonUrl={lastCTASectionButtonUrl}
        buttonText={lastCTASectionButtonText}
      />
    </Layout>
  )
}
export const query = graphql`
  query AboutPageQuery {
    allWordpressWpTechnologyTaxonomy {
      nodes {
        name
        wordpress_id
      }
    }
    allWordpressWpTechnology {
      nodes {
        title
        featured_media {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        technology_taxonomy
      }
    }
    wordpressPage(template: { eq: "page-templates/page-about.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_about {
          getToKnowTitle
          getToKnowContentTitle
          getToKnowContentText
          cultureSectionTitle
          peopleSectionTitle
          peopleSectionContentTitle
          peopleSectionContentText
          listSectionTitle
          reviewsSectionTitle
          supportSectionTitle
          technologySectionTitle
          lastCTASectionTitle
          lastCTASectionText
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          culture {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            text
          }
          list {
            title
            contentTitle
            contentText
            buttonText
            buttonUrl
          }
          reviews {
            content
            referrerName
            referrerPosition
            referrerImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          team {
            name
            role
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 230) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          supportSliderTitle
          supportSliderText
          support {
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          getToKnowImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          peopleSectionImage1 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          peopleSectionImage2 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          peopleSectionImage3 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage
