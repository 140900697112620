import React from "react"
import styled from "styled-components"
import BaseImg from "../baseImg"
import { useSwiper } from "../hooks/useSwiper"
import { Container } from "../styledComponents"
import { useAnimatedBorder } from "../../helpers"

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @media screen and (max-width: 1195px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  @media screen and (max-width: 768px) {
    margin-top: 70px;
  }
`

const SupportSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1195px) {
    flex-direction: column;
  }
`

const SlideContent = styled.div`
  width: 70%;
  padding: 3rem 100px 3rem 3rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.secondary};
  border-right: none;
  @media screen and (max-width: 1195px) {
    text-align: center;
    padding: 2.25rem 2rem;
    border-left: none;
    margin: 40px 10px 0;
    ::before,
    ::after {
      border-left: 1px solid ${(props) => props.theme.secondary};
    }
  }
  ::before,
  ::after {
    content: "";
    position: absolute;
    display: block;
    right: 0;
    height: 60px;
    width: 100%;
    border-right: 1px solid ${(props) => props.theme.secondary};
    transition: transform 1s ease-out;
  }
  ::before {
    top: 0;
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
  }
  ::after {
    bottom: 0;
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: bottom;
  }
`

const SlideImg = styled(BaseImg)`
  width: 100%;
  max-width: 280px;
  @media screen and (max-width: 1195px) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SliderControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
  justify-content: flex-end;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 5%;
  @media screen and (max-width: 1195px) {
    position: static;
    transform: none;
    justify-content: center;
  }
`

const ArrowButton = styled.button`
  background: none;
  border: none;
  height: 40px;
  cursor: pointer;
  margin-${(props) => (props.side === "left" ? "right" : "left")}: 30px;
  position: relative;
  :focus{
    outline: none;
    ::before{
      border-color: ${(props) => props.theme.primary};
    }
  }
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-bottom: 2px ${(props) => props.theme.secondary} solid;
    border-left: 2px ${(props) => props.theme.secondary} solid;
    transform: translate(${(props) =>
      props.side === "left" ? "-20%" : "-80%"}, -50%)
      rotate(${(props) => (props.side === "left" ? "45deg" : "-135deg")});
      position: absolute;
    }
    `

const SupportSlider = ({ support, title, text }) => {
  const [scale, boxRef] = useAnimatedBorder()
  const { swiperElement, swiperInstance } = useSwiper(support)

  return (
    <div style={{ margin: "70px 0 140px 0" }}>
      <Container size="medium">
        <SupportSection>
          <SlideContent scale={scale} ref={boxRef}>
            <h3
              style={{
                fontSize: "24px",
                lineHeight: "29px",
                marginBottom: "1rem",
              }}
            >
              {title}
            </h3>
            <p
              dangerouslySetInnerHTML={{ __html: text }}
              style={{ marginBottom: "0" }}
            ></p>
          </SlideContent>
          <div
            style={{ width: "30%" }}
            className="swiper-container"
            ref={swiperElement}
          >
            <ul style={{ margin: "0" }} className="swiper-wrapper">
              {support.map(({ image }) => {
                return (
                  <Slide className="swiper-slide" key={image.id}>
                    <SlideImg localFile={image.localFile} />
                  </Slide>
                )
              })}
            </ul>
          </div>
          <SliderControlsWrapper>
            <ArrowButton
              type="button"
              aria-label="Show previous slide"
              side="left"
              onClick={() => swiperInstance.current.slidePrev()}
            />
            <ArrowButton
              type="button"
              aria-label="Show next slide"
              onClick={() => swiperInstance.current.slideNext()}
            />
          </SliderControlsWrapper>
        </SupportSection>
      </Container>
    </div>
  )
}

export default SupportSlider
