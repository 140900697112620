import React, { useState } from "react"
import { Container } from "./styledComponents"
import CenterBorderedHeader from "./centerBorderedHeader"
import styled from "styled-components"
import { BigMobileFont } from "./typography"
import BaseImg from "./baseImg"

const Technologies = styled.div`
  position: relative;
  height: 210px;
  overflow: hidden;
  .category {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transition: transform 0.1s ease-in;
    transform: translateY(-100%);
  }
  .category.active {
    transform: translateY(0);
  }
`

const Categories = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 1195px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  .category {
    cursor: pointer;
    margin: 2rem;
    background: none;
    border: none;
    color: ${(props) => props.theme.primary};
    font: 700 1.5rem/1.8125rem "Montserrat";
    :focus {
      outline: none;
    }
    @media (max-width: 1195px) {
      margin: 0.5rem 2rem;
    }
  }
  .category.active {
    text-decoration: line-through ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
  }
`

const TechnologiesSection = ({ title, technologies, technologyTaxonomy }) => {
  const [activeCategory, setActiveCategory] = useState(
    technologyTaxonomy[0].wordpress_id
  )
  const technologiesObject = {}
  for (const technology of technologies) {
    for (const taxonomy of technology.technology_taxonomy) {
      const technologyToAdd = {
        title: technology.title,
        featured_media: technology.featured_media,
      }
      if (Array.isArray(technologiesObject[taxonomy])) {
        technologiesObject[taxonomy].push(technologyToAdd)
      } else {
        technologiesObject[taxonomy] = [technologyToAdd]
      }
    }
  }

  return (
    <Container>
      <CenterBorderedHeader>
        <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
      </CenterBorderedHeader>
      <Categories>
        {technologyTaxonomy.map((taxonomy) => (
          <button
            key={taxonomy.wordpress_id}
            onClick={() => setActiveCategory(taxonomy.wordpress_id)}
            className={
              taxonomy.wordpress_id === activeCategory
                ? "active category"
                : "category"
            }
          >
            {taxonomy.name}
          </button>
        ))}
      </Categories>
      <Technologies>
        {technologyTaxonomy.map((taxonomy) => (
          <div
            key={taxonomy.wordpress_id}
            className={
              taxonomy.wordpress_id === activeCategory
                ? "active category"
                : "category"
            }
          >
            {technologiesObject[taxonomy.wordpress_id].map((technology) => (
              <div
                style={{
                  flexGrow: "1",
                  margin: "2%",
                }}
              >
                <BaseImg
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                  style={{
                    maxHeight: "150px",
                  }}
                  key={technology.title}
                  localFile={technology.featured_media?.localFile}
                  alt={technology.title}
                />
              </div>
            ))}
          </div>
        ))}
      </Technologies>
    </Container>
  )
}

export default TechnologiesSection
